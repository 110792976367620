<template>
  <header
    id="header"
    ref="header"
    :class="{
      atHome: routeName === 'Home',
      isEn: $store.state.site.lang === 'EN',
      fixed: headerFixed,
    }"
  >
    <div class="container txt-dark">
      <router-link to="/" class="logo_wrapper">
        <img src="../assets/imgs/index/logo_header.svg" alt="">
      </router-link>
      <nav>
        <ul>
          <li
            v-for="(link, idx) in MenuList"
            :key="`link_${idx}`"
          >
            <router-link class="large" :to="routeData(link)">
              {{ convertToEnglish(link.name, link.name_en) }}
            </router-link>

            <!-- Sub Menu -->
            <div
              v-if="link.list"
              class="hover_menu"
            >
              <ul>
                <li
                  v-for="(item, idx1) in (link.name === '產品介紹' ? serieses : locations)"
                  :key="`${link.name}_${idx1}`"
                >
                  <router-link
                    :to="routeData(link, item)"
                  >
                    {{ convertToEnglish(item.name, item.name_en) }}
                  </router-link>
                </li>
              </ul>
            </div>

          </li>
        </ul>
      </nav>
      <div class="operate_wrapper">
        <div class="search_wrapper">
          <label for="keyword_pc">
            <div class="click_area" @click="spreadInput()">
              <img class="icon-search" src="../assets/imgs/index/icon_search.svg" alt="">
            </div>
            <input
              id="keyword_pc"
              class="keyword txt-left"
              :class="{ active: isInputSpread }"
              type="text"
              placeholder="搜尋產品"
              v-model.trim="keyword"
              @keyup.enter="enterHandler()"
              @blur="blurHandler()"
              @input="updateComposingStatus($event)"
            >
            <NextArrow
              v-show="isInputSpread"
              class="search_btn"
              fillColor="#D30025"
              @click.native="enterHandler()"
            />
          </label>
        </div>
        <div class="language_wrapper">
          <img
            class="icon-language"
            src="../assets/imgs/index/icon_language.svg" alt=""
            @click="
              isMobileLanguage = !isMobileLanguage;
              isMobileSearch = false;
              isMobileNav = false;
            "
          >
          <button
            @click="switchLanguage('TW')"
            class="small"
            :class="{ active: language === 'TW' }"
          >TW</button>
          <button
            @click="switchLanguage('EN')"
            class="small"
            :class="{ active: language === 'EN' }"
          >EN</button>
        </div>
        <Hamburger
          :class="{ active: isMobileNav }"
          @click.native="toggleHamburger()"
        />
      </div>
    </div>
    <div
      class="search_box"
      :class="{ active: isMobileSearch }"
    >
      <label for="keyword_mo">
        <input
          id="keyword_mo"
          class="txt-left"
          :class="{ active: true }"
          type="text"
          v-model.trim="keyword"
          placeholder="搜尋產品"
          @keyup.enter="enterHandler()"
          @blur="blurHandlerMo()"
          @input="updateComposingStatus($event)"
        >
        <button
          v-show="isMobileSearch"
          class="search_btn"
          @click="enterHandler()"
        >
          <NextArrow
            fillColor="#D30025"
          />
        </button>
      </label>
    </div>
    <div
      class="language_box txt-center"
      :class="{ active: isMobileLanguage }"
    >
      <div>
        <button
          @click="switchLanguage('TW')"
          :class="{ active: language === 'TW' }"
        >TW</button>
      </div>
      <div>
        <button
          @click="switchLanguage('EN')"
          :class="{ active: language === 'EN' }"
        >EN</button>
      </div>
    </div>
    <div
      class="header_menu_mobile"
      :class="{ active: isMobileNav }"
      :style="{ height: `calc(100vh - ${headerHeight}px)` }"
    >
      <div class="cover" @click="isMobileNav = false"></div>
      <ul>
        <li
          v-for="(link, idx) in MenuList"
          :key="`link_${idx}`"
        >
          <template v-if="link.list">
            <div
              class="list_title"
              :class="{ active: mobileNavActiveIdx === idx }"
              @click="updateMobileNavActiveIdx(idx)"
            >
              <span
                class="large"
                :class="{ 'txt-main': mobileNavActiveIdx === idx }"
              >{{ convertToEnglish(link.name, link.name_en) }}</span>
              <NextArrow
                class="arrow_icon"
                :class="{ active: mobileNavActiveIdx === idx  }"
              />
            </div>

            <!-- Sub Menu -->
            <VueSlideToggle :open="mobileNavActiveIdx === idx" :duration="500">
              <div
                v-if="link.list"
                class="hover_menu"
              >
                <ul>
                  <li
                    v-for="(item, idx1) in (link.name === '產品介紹' ? serieses : locations)"
                    :key="`${link.name}_${idx1}`"
                  >
                    <router-link
                      :to="routeData(link, item)"
                    >
                      {{ convertToEnglish(item.name, item.name_en) }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </VueSlideToggle>
          </template>
          <template v-else>
            <router-link
              class="list_title"
              :to="{ name: link.to }"
            >
              <span class="large">{{ convertToEnglish(link.name, link.name_en) }}</span>
            </router-link>
          </template>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import NextArrow from '@/components/icon/NextArrow.vue';
import Hamburger from '@/components/hamburger/Index.vue';
import { isEqual, set } from '@/lib/lodash';
import { MenuList } from '@/lib/const';

import languageMixin from '@/mixins/languageMixin';
import goToProductMixin from '@/mixins/goToProductMixin';
import requestApi from '@/lib/http';
import { formatSerieses, formatLocations } from '@/utils/formatResponse';

/**
 * Body Locked
 */
function bodyLock(status) {
  const bodyTarget = document.getElementsByTagName('body')[0];
  if (status) {
    bodyTarget.classList.add('lock');
  } else {
    bodyTarget.classList.remove('lock');
  }
}

export default {
  name: 'Header',
  mixins: [languageMixin, goToProductMixin],
  components: {
    VueSlideToggle,
    NextArrow,
    Hamburger,
  },
  data() {
    return {
      MenuList,
      composingStatus: false,
      isInputSpread: false,
      isMobileSearch: false,
      isMobileLanguage: false,
      isMobileNav: false,
      mobileNavActiveIdx: null,
      routeName: 'Home',
      keyword: '',
      language: 'TW',
      serieses: [],
      locations: [],
    };
  },
  computed: {
    headerFixed() {
      return this.$store.state.site.headerFixed;
    },
    headerHeight() {
      return this.$store.state.site.headerHeight;
    },
  },
  methods: {
    blurHandler() {
      if (!this.keyword) {
        this.isInputSpread = false;
      }
    },
    blurHandlerMo() {
      if (!this.keyword) {
        this.isMobileSearch = false;
      }
    },
    enterHandler() {
      if (this.composingStatus) {
        this.composingStatus = false;
        return;
      }
      if (this.keyword) {
        this.$store.commit('site/SET_SEARCH_KEYWORD', this.keyword);
        if (this.$route.name !== 'Search') {
          this.$router.push({ name: 'Search' });
        }
        this.keyword = '';
        this.isInputSpread = false;
      }
    },
    spreadInput() {
      if (window.screen.width >= 1700) {
        if (this.isInputSpread) {
          this.enterHandler();
        } else {
          this.isInputSpread = true;
        }
      } else {
        this.isMobileSearch = !this.isMobileSearch;
        this.isMobileLanguage = false;
        this.isMobileNav = false;
      }
    },
    switchLanguage(language) {
      this.language = language;
      this.$store.commit('site/SET_LANG', language);

      const { lang = 'TW' } = this.$route.query;
      if (lang !== language) {
        this.$router.push({ query: { ...this.$route.query, lang: language } });
      }

      this.isMobileLanguage = false;
    },
    toggleHamburger() {
      this.isMobileSearch = false;
      this.isMobileLanguage = false;
      this.isMobileNav = !this.isMobileNav;
    },
    updateMobileNavActiveIdx(idx) {
      if (this.mobileNavActiveIdx !== null) {
        if (this.mobileNavActiveIdx !== idx) {
          this.mobileNavActiveIdx = idx;
        } else {
          this.mobileNavActiveIdx = null;
        }
      } else {
        this.mobileNavActiveIdx = idx;
      }
    },
    updateComposingStatus(e) {
      if (e.isComposing === true || e.isComposing === false) {
        this.composingStatus = e.isComposing;
      }
    },
    async getSerieses() {
      const { entries: data } = await requestApi('common.getSerieses', {
        // limit: 7, // 首頁排版最多顯示7筆
      });
      this.serieses = data.map(formatSerieses).sort((a, b) => a.sort - b.sort);
    },
    async getLocations() {
      const { entries: data } = await requestApi('common.getParts');
      this.locations = data.map(formatLocations);
    },
    routeData(parent, child) {
      let result = {
        // eslint-disable-next-line no-nested-ternary
        name: parent.name === '產品介紹'
          ? 'Products'
          : parent.name === '經銷據點'
            ? 'Marketing'
            : parent.to,
      };
      if (parent.name === '產品介紹') {
        const lv1 = child
          ? child.name
          : this.menuListFromVuex?.[0]?.name;
        const lv2 = child
          ? this.menuListFromVuex
            ?.find((main) => main.name === child.name)?.list?.[0]?.name
          : this.menuListFromVuex?.[0]?.list?.[0]?.name;

        result = this.getProductRouteDate(result, lv1, lv2);
      } else if (parent.name === '經銷據點' && child) {
        set(result, 'query.series_main', child.name);
      }
      return result;
    },
  },
  mounted() {
    this.getSerieses();
    this.getLocations();
  },
  updated() {
    /**
     * Get header height for inner page's padding-top
     */
    const headerHeight = this.$refs.header.clientHeight;
    this.$store.commit('site/SET_HEADER_HEIGHT', headerHeight);
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        const initLanguage = () => {
          const { lang } = this.$route.query;
          if (lang) {
            this.language = lang;
            this.$store.commit('site/SET_LANG', lang);
          }
        };

        if (!isEqual(newVal, oldVal)) {
          initLanguage();

          this.routeName = newVal.name;
          this.isMobileSearch = false;
          this.isMobileNav = false;
          this.isMobileLanguage = false;
        }
      },
    },
    isMobileNav: {
      handler(val) {
        bodyLock(val);
      },
    },
  },
};
</script>
