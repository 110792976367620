/* eslint-disable no-param-reassign */
import axios from 'axios';
import qs from 'qs';

import httpList from './api/index';

import { API_URL } from '../config';

const isDev = process.env.DEV;

// let AUTH_TOKEN = null;
let AUTH_TOKEN = '05209af254120c161d008574d0550d';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response) {
      return error.response;
    }
    return {
      data: {
        status: false,
        massage: '伺服器無回應，請檢查您的網路',
        data: null,
      },
    };
  },
);

export const setToken = (token = null) => {
  AUTH_TOKEN = token;
};

function buildFormData(formData, data, parentKey) {
  if (
    data
    && typeof data === 'object'
    && !(data instanceof Date)
    && !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

function toFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

function request(
  url,
  data = false,
  method = 'get',
  token = false,
  headers = {},
) {
  if (token) {
    if (AUTH_TOKEN === null) {
      return new Promise((resolve) => {
        resolve({
          status: false,
          message: '本地token無效',
          data: null,
        });
      });
    }
    headers = { ...headers, authorization: `Bearer ${AUTH_TOKEN}` };
  }
  let req = {
    url,
    method,
    headers,
    Accept: 'application/json, text/plain, */*',
    mode: 'no-cors',
    timeout: 20000,
  };

  if (method === 'post' && data !== false) {
    headers = {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    req = { ...req, data: qs.stringify(data), headers };
  }

  if (method === 'file' && data !== false) {
    headers = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    };
    method = 'post';
    req = {
      ...req, method, data: toFormData(data), headers,
    };
  }

  if (['get'].includes(method) && data !== false) {
    req = { ...req, params: data, headers };
  }

  if (isDev) console.info('⭕ http req ', req);

  return axios(req)
    .then((response) => {
      if (isDev) console.info('⭕ http response', response.data);
      return response.data;
    })
    .catch((err) => {
      if (isDev) console.log('❌ http err', err.response.data);
      return err.response.data;
    });
}

export default function main(name = null, options = {}) {
  if (!name) {
    return null;
  }
  const [controller, action = 'index'] = name.split('.');

  const moduleSpecifier = httpList[controller];

  const apiSpecifier = moduleSpecifier[action];

  const url = `${process.env.API_URL || API_URL}/${moduleSpecifier.baseUrl}${apiSpecifier.url}`;

  const {
    params,
    fun,
    method = 'get',
    header = {},
    token = true,
  } = apiSpecifier;

  const data = { ...params, ...options };

  return request(url, data, method, token, header).then((response) => {
    // isDev && console.info('👉👉 response', response);
    if (fun && typeof fun === 'function') {
      return fun(response);
    }
    return response;
  });
}
