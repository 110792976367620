export default {
  baseUrl: 'api/collections/get/',

  /**
   * @name 產品系列
   */
  getSerieses: {
    url: 'menu',
    method: 'post',
    // token: false,
    data: {
      filter: {
        title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
        content: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
      lang: '', // 語系default(預設語系:繁中),eng(英文)，未指定會全部都給
    },
  },

  /**
   * @name 產品列表
   */
  getProducts: {
    url: 'products',
    method: 'post',
    // token: false,
    data: {
      filter: {
        title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
        content: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
      lang: '', // 語系default(預設語系:繁中),eng(英文)，未指定會全部都給
    },
  },

  /**
   * @name 最新消息
   */
  getNews: {
    url: 'news',
    method: 'post',
    // token: false,
    data: {
      filter: {
        title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
        content: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
      lang: '', // 語系default(預設語系:繁中),eng(英文)，未指定會全部都給
    },
  },

  /**
   * @name 經銷大分區
   */
  getParts: {
    url: 'partlist',
    method: 'post',
    // token: false,
    data: {
      filter: {
        title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
        content: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
      lang: '', // 語系default(預設語系:繁中),eng(英文)，未指定會全部都給
    },
  },

  /**
   * @name 縣市列表
   */
  getCities: {
    url: 'country',
    method: 'post',
    // token: false,
    data: {
      filter: {
        title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
        content: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
      lang: '', // 語系default(預設語系:繁中),eng(英文)，未指定會全部都給
    },
  },

  /**
   * @name 經銷據點
   */
  getStores: {
    url: 'store',
    method: 'post',
    // token: false,
    data: {
      filter: {
        title: '', // 搜尋標題 filter[指定欄位] 只能完全比對
      },
      fields: {
        title: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
        content: '', // 指定輸入欄位fields[欄位名稱]，未指定全部輸出
      },
      limit: null, // 指定每頁輸出筆數(分頁用)，未指定全部輸出
      skip: 0, // 指定從第幾筆開始 0 第一筆 1 第二筆，以此類推(分頁用)
      sort: {
        title: '', // 排序sort[欄位] 1(正排) -1(逆排)
      },
      lang: '', // 語系default(預設語系:繁中),eng(英文)，未指定會全部都給
    },
  },
};
