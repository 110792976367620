function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
  return '';
}

export default {
  created() {
    const defaultTitle = '六協興業 Atlantic Chef';
    const title = getTitle(this);
    if (title) {
      // document.title = `${title} | 台灣居家醫療醫學會`;
      document.title = `${defaultTitle}｜ ${title}`;
    } else {
      // document.title = `${defaultTitle}｜ 舒適好襪子、耐穿四角褲，三花的好，穿過都知道。`;
      document.title = `${defaultTitle}`;
    }
  },
};
