export default {
  computed: {
    lang() {
      return this.$store.state.site.lang;
    },
  },
  methods: {
    convertToEnglish(cn, en) {
      if (this.lang === 'EN') {
        return en || cn;
      }
      return cn;
    },
  },
};
