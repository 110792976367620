<template>
  <div class="modal" @click="clickHandler()">
    <div
      class="modal_content noScrollBar"
      :class="[
        size,
        position ? `position-${position}` : '',
        isZoomIn ? 'overflowHidden' : '',
      ]"
      :style="{ backgroundColor: backgroundColor }"
      @click.stop
    >
      <button
        class="close_btn"
        @click="clickHandler()"
      >
        <!-- <font-awesome-icon icon="times" /> -->
        <Hamburger
          class="active"
        />
      </button>

      <component
        v-show="contentComponent"
        :is="contentComponent"
        v-bind="propsData"
        @confirm="confirm"
        @cancel="cancel"
        @zoomUpdate="zoomUpdate"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { cloneDeep } from '@/lib/lodash';
import Hamburger from '@/components/hamburger/Index.vue';
import ModalDefault from '@/components/modal/ModalDefault.vue';

export default {
  name: 'Modal',
  components: {
    Hamburger,
  },
  data() {
    return {
      isZoomIn: false,
    };
  },
  computed: {
    ...mapState({
      show: (state) => state.site.modal.show,
      position: (state) => state.site.modal.position,
      size: (state) => state.site.modal.size,
      backgroundColor: (state) => state.site.modal.backgroundColor,
      contentComponent: (state) => cloneDeep(state?.site.modal?.contentComponent) || ModalDefault,
      propsData: (state) => cloneDeep(state.site.modal?.propsData) ?? {},
      confirmCallback: (state) => state.site.modal.confirmCallback,
      cancelCallback: (state) => state.site.modal.cancelCallback,
    }),
  },
  methods: {
    ...mapMutations('site', ['SET_MODAL']),
    closeModal() {
      this.SET_MODAL({
        show: false,
        position: '',
        size: 'md',
        contentComponent: null,
        propsData: {},
        confirmCallback: null,
        cancelCallback: null,
      });
    },
    clickHandler() {
      this.closeModal();
    },
    confirm(val) {
      if (this.confirmCallback) this.confirmCallback(val);
      this.closeModal();
    },
    cancel() {
      if (this.cancelCallback) this.cancelCallback();
      this.closeModal();
    },
    zoomUpdate(val) {
      this.isZoomIn = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.7);
  z-index: 999;
  .modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 50px;
    background-color: #fff;
    width: 90%;
    padding-top: 50px;
    border-top: 5px solid $color-red;
    &.full {
      width: 100%;
    }
    &.sm-x {
      max-width: 450px;
    }
    &.sm {
      max-width: 650px;
    }
    &.md {
      max-width: 900px;
    }
    &.lg {
      max-width: 1200px;
    }
    &.position-bottom {
      top: auto;
      bottom: 0;
      transform: translate(-50%, 0);
    }
    &.position-top {
      top: 0;
      bottom: auto;
      transform: translate(-50%, 0);
    }
    .close_btn {
      position: absolute;
      top: 5px;
      right: 15px;
      padding: 10px;
      z-index: 1;
      svg {
        display: block;
        transform: scale(1.5);
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .modal {
    .modal_content {
      padding-top: 80px;
      width: 100%;
      height: 100%;
      max-width: 100% !important;
      border-top: 0;
      overflow: auto;
      &.overflowHidden {
        overflow: hidden;
      }
    }
  }
}
// @media screen and (max-width: 600px) {
//   .modal {
//     .modal_content {
//       padding-top: 30px;
//       .close_btn {
//         top: -2px;
//         right: 4px;
//         svg {
//           display: block;
//           transform: scale(1.2);
//         }
//       }
//     }
//   }
// }
</style>
