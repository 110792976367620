<template>
  <div class="modal_default">

    <!-- Title -->
    <div class="modal_default_title">

    </div>

    <!-- Content -->
    <div
      v-show="content"
      v-html="content"
      class="modal_default_content txt-center p"
    ></div>

    <!-- Component -->
    <component
      v-show="propComponent"
      :is="propComponent"
    />

    <!-- Buttons -->
    <div class="modal_default_btns">

    </div>

  </div>
</template>

<script>
export default {
  name: 'ModalDefault',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '溫馨提示',
    },
    content: {
      type: String,
      default: '',
    },
    propComponent: {
      type: Object,
      default: null,
    },
    confirmText: {
      type: String,
    },
    cancelText: {
      type: String,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.modal_default {
  padding: 10%;
  padding-top: 0;
  > div {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
  &_btns {
    display: flex;
    justify-content: center;
    button {
      margin: 0 5px;
      max-width: 150px;
    }
  }
}
@media screen and (max-width: 480px) {
  .modal_default {
    &_btns {
      flex-direction: column-reverse;
      button {
        max-width: initial;
        margin: 0;
        &.btn-confirm {
          margin-bottom: 15px;
          color: #fff;
          background-color: $color-main;
        }
      }
    }
  }
}
</style>
