import { set, cloneDeep } from '@/lib/lodash';

export default {
  computed: {
    menuListFromVuex() {
      return this.$store.state.site.menuList;
    },
  },
  methods: {
    getProductRouteDate(currentRouteData, lv1, lv2) {
      const result = cloneDeep(currentRouteData);
      set(result, 'params.lv1', lv1);
      set(result, 'params.lv2', lv2);
      set(result, 'query.page', 1);
      return result;
    },
  },
};
