export default {
  methods: {
    /** 頁面滑至頂端 */
    scrollToTop(duration = 1) {
      if (document.scrollingElement.scrollTop === 0) return;

      const totalScrollDistance = document.scrollingElement.scrollTop;
      let scrollY = totalScrollDistance;
      let oldTimestamp = null;

      // eslint-disable-next-line consistent-return
      function step(newTimestamp) {
        if (oldTimestamp !== null) {
          // if duration is 0 scrollY will be -Infinity
          scrollY -= (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration;
          // eslint-disable-next-line no-return-assign
          if (scrollY <= 0) return document.scrollingElement.scrollTop = 0;
          document.scrollingElement.scrollTop = scrollY;
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
    },

    /** 滑至指定對象 */
    scrollTo(scrollTo, scrollDuration, scrollAdjust) {
      //
      // Set a default for where we're scrolling to
      //
      if (typeof scrollTo === 'string') {
        // Assuming this is a selector we can use to find an element
        const scrollToObj = document.querySelector(scrollTo);

        if (scrollToObj && typeof scrollToObj.getBoundingClientRect === 'function') {
          // eslint-disable-next-line no-param-reassign
          scrollTo = window.pageYOffset + scrollToObj.getBoundingClientRect().top;
        } else {
          // eslint-disable-next-line no-throw-literal
          throw `error: No element found with the selector "${scrollTo}""`;
        }
      } else if (typeof scrollTo !== 'number') {
        // If it's nothing above and not an integer, we assume top of the window
        // eslint-disable-next-line no-param-reassign
        scrollTo = 0;
      }

      // Set this a bit higher
      const anchorHeightAdjust = scrollAdjust || 200;
      if (scrollTo > anchorHeightAdjust) {
        // eslint-disable-next-line no-param-reassign
        scrollTo -= anchorHeightAdjust;
      }

      //
      // Set a default for the duration
      //
      if (typeof scrollDuration !== 'number' || scrollDuration < 0) {
        // eslint-disable-next-line no-param-reassign
        scrollDuration = 1000;
      }

      // Declarations
      const cosParameter = (window.pageYOffset - scrollTo) / 2;
      let scrollCount = 0;
      let oldTimestamp = window.performance.now();

      function step(newTimestamp) {
        let tsDiff = newTimestamp - oldTimestamp;

        // Performance.now() polyfill loads late so passed-in timestamp is a larger offset
        // on the first go-through than we want so I'm adjusting the difference down here.
        // Regardless, we would rather have a slightly slower animation than a big jump so a good
        // safeguard, even if we're not using the polyfill.

        if (tsDiff > 100) {
          tsDiff = 30;
        }

        scrollCount += Math.PI / (scrollDuration / tsDiff);

        // As soon as we cross over Pi, we're about where we need to be

        if (scrollCount >= Math.PI) {
          return;
        }

        const moveStep = Math.round(scrollTo + cosParameter + cosParameter * Math.cos(scrollCount));
        window.scrollTo(0, moveStep);
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }

      window.requestAnimationFrame(step);
    },
  },
};
