/* eslint-disable camelcase */

import { API_URL } from '../lib/config';

function apiPrefix(pic_url) {
  if (pic_url) return `${API_URL}/${pic_url}`;
  return '';
}

export const formatSerieses = ({
  title = '',
  title_eng = '',
  pic,
  order_num = null,
  _o = null,
} = {}) => ({
  name: title,
  name_en: title_eng,
  pic: apiPrefix(pic?.path),
  order: order_num,
  sort: _o,
});

export const formatNews = ({
  _id = null,
  title = '',
  title_eng = '',
  intro = '',
  intro_eng = '',
  content = '',
  content_eng = '',
  publish_date = '',
  cover = null,
  video = '',
  attachment = null,
  status = '',
} = {}) => ({
  uuid: _id,
  title,
  title_en: title_eng,
  description: intro,
  description_en: intro_eng,
  content,
  content_en: content_eng,
  pic: apiPrefix(cover?.path),
  videoUrl: video,
  created: publish_date,
  attachment: apiPrefix(attachment),
  status,
});

export const formatProduct = ({
  lv1 = {},
  lv2 = '',
  lv2_eng = '',
  title = '',
  title_eng = '',
  pro_no = '',
  set_size = '',
  weight = '',
  product_images = [],
  blade_pic = '',
} = {}) => ({
  lv1: lv1.display,
  lv2,
  lv2_en: lv2_eng,
  name: title,
  name_en: title_eng,
  no: pro_no,
  size: set_size,
  weight,
  colors: product_images?.map((item) => ({
    name: item.value.color,
    value: item.value.color,
    pic: apiPrefix(item?.value?.image?.path) || [],
  })),
  blade_pic: apiPrefix(blade_pic?.path),
});

export const formatLocations = ({
  partname = '',
  partname_eng = '',
} = {}) => ({
  name: partname,
  name_en: partname_eng,
});

export const formatCities = ({
  city = '',
  city_eng = '',
} = {}) => ({
  name: city,
  name_en: city_eng,
});

export const formatMarkegints = ({
  part = '',
  part_eng = '',
  _id = '',
  title = '',
  title_eng = '',
  address = '',
  address_eng = '',
  tel = '',
  fax = '',
  google_map = '',
  tag = [],
  tag_eng = [],
  city = {},
  city_eng = '',
  status = '',
} = {}) => ({
  section: part,
  section_en: part_eng,
  uuid: _id,
  name: title,
  name_en: title_eng,
  address,
  address_en: address_eng,
  tel,
  fax,
  map: google_map,
  tags: tag,
  tags_en: tag_eng,
  city: {
    name: city.display,
    name_en: city_eng,
  },
  status: status === 'Y',
});
