import requestApi from '@/lib/http';
import { formatSerieses } from '@/utils/formatResponse';

const site = {
  namespaced: true,
  state: () => ({
    headerHeight: null,
    headerFixed: false,

    allSeriesesInOrder: [],
    menuList: [],

    modal: {
      show: false,
      position: '', // top or bottom
      size: 'md',
      backgroundColor: '#fff',
      contentComponent: null,
      propsData: {},
      confirmCallback: null,
      cancelCallback: null,
    },

    lang: 'TW',
    searchKeyword: '',
  }),
  mutations: {
    SET_HEADER_HEIGHT(state, val) {
      state.headerHeight = val;
    },
    SET_HEADER_FIXED(state, status) {
      state.headerFixed = status;
    },

    SET_ALL_SERIESES_IN_ORDER(state, data) {
      state.allSeriesesInOrder = data;
    },
    SET_MENULIST(state, data) {
      state.menuList = data;
    },

    SET_MODAL(state, payload) {
      state.modal = {
        ...state.modal,
        ...payload,
      };
    },

    SET_LANG(state, val) {
      state.lang = val;
    },

    SET_SEARCH_KEYWORD(state, val) {
      state.searchKeyword = val;
    },
  },
  actions: {
    async initAllSerieses({ commit }) {
      let result = [];
      const getSeriesesInOrder = (serieses) => {
        result = serieses
          .map(formatSerieses)
          .sort((a, b) => a.sort - b.sort);
      };

      const { entries: data } = await requestApi('common.getSerieses');
      getSeriesesInOrder(data);
      commit('SET_ALL_SERIESES_IN_ORDER', result);
    },
    async initMenuList({ commit, state }) {
      let result = [];
      const orderedSerieses = () => state.allSeriesesInOrder.map((series) => series.name);
      const formatMenuSerieses = (data) => {
        const seriesesFormat = [];

        // 組成主系列
        const mainSerieses = data.reduce((acc, cur) => {
          if (!acc.length || acc.indexOf(cur.lv1.display) === -1) {
            acc.push(cur.lv1.display);
          }
          return acc;
        }, []);

        // 主系列下組成子系列
        mainSerieses.forEach((name) => {
          const series = {
            uuid: null,
            name,
            name_en: '',
            list: [],
          };

          data.forEach((item) => {
            if (item.lv1.display === name) {
              // eslint-disable-next-line no-underscore-dangle
              series.uuid = item.lv1._id;
              series.name_en = state.allSeriesesInOrder?.find((seriesFromAll) => seriesFromAll.name === item.lv1.display).name_en || '';

              if (!series.list.length) {
                series.list.push({
                  name: item.lv2,
                  name_en: item.lv2_eng,
                });
              } else {
                const subSerieses = series.list.map((subSeries) => subSeries.name);
                if (subSerieses.indexOf(item.lv2) === -1) {
                  series.list.push({
                    name: item.lv2,
                    name_en: item.lv2_eng,
                  });
                }
              }
            }
          });
          seriesesFormat.push(series);
        });

        result = seriesesFormat
          .sort((a, b) => orderedSerieses().indexOf(a.name) - orderedSerieses().indexOf(b.name));
      };

      const { entries: products } = await requestApi('common.getProducts', {
        fields: {
          lv1: 1,
          lv2: 1,
          lv2_eng: 2,
        },
        sort: {
          _o: 1, // 按後台商品列表顯示順序排列
        },
      });
      formatMenuSerieses(products);
      commit('SET_MENULIST', result);
    },
  },
  getters: {

  },
};

export default site;
