<template>
  <button class="link_more">
    <span class="link_more_text p">{{ linkText }}</span>
    <NextArrow class="link_more_icon" :fillColor="fillColor" />
  </button>
</template>

<script>
import NextArrow from '@/components/icon/NextArrow.vue';

export default {
  name: 'LinkMore',
  props: {
    linkText: {
      type: String,
      default: '',
    },
    fillColor: {
      type: String,
      default: '#D30025',
    },
  },
  components: {
    NextArrow,
  },
};
</script>

<style lang="scss" scoped>
.link_more {
  display: flex;
  align-items: center;
  .link_more_text {
    padding-right: 10px;
  }
  .link_more_icon {
    position: relative;
    left: 0;
    transition: left 0.2s linear;
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      .link_more_icon {
        left: 10px;
      }
    }
  }
}
</style>
