import seriesPic01 from '../assets/imgs/index/products_1.png';
import seriesPic02 from '../assets/imgs/index/products_2.png';
import seriesPic03 from '../assets/imgs/index/products_3.png';
import seriesPic04 from '../assets/imgs/index/products_4.png';
import seriesPic05 from '../assets/imgs/index/products_5.png';
import seriesPic06 from '../assets/imgs/index/products_6.png';
import seriesPic07 from '../assets/imgs/index/products_7.png';

import productPic from '../assets/imgs/products/product.png';

import knowledgePic01 from '../assets/imgs/knowledge/img_1.png';
import knowledgePic02 from '../assets/imgs/knowledge/img_2.png';
import knowledgePic03 from '../assets/imgs/knowledge/img_3.png';
import knowledgePic04 from '../assets/imgs/knowledge/img_4.png';
import knowledgePic05 from '../assets/imgs/knowledge/img_5.png';

export const MenuList = [
  {
    name: '產品介紹',
    name_en: 'Product',
    to: 'Products',
    list: true,
  },
  {
    name: '關於六協',
    name_en: 'About',
    to: 'About',
  },
  {
    name: '最新消息',
    name_en: 'NEWS',
    to: 'News',
  },
  {
    name: '專業知識',
    name_en: 'Knowledge',
    to: 'Knowledge',
  },
  {
    name: '經銷據點',
    name_en: 'Locations',
    to: 'Marketing',
    list: true,
  },
  {
    name: '聯絡我們',
    name_en: 'Contact',
    to: 'Contact',
  },
];

export const serieses = [
  {
    name: '西廚系列',
    name_en: 'Western Kitchen Series',
    pic: seriesPic01,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F)',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
  {
    name: '中廚系列',
    name_en: 'Chinese Style Knives',
    pic: seriesPic02,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F))',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
  {
    name: '日廚系列',
    name_en: 'Japanese Style Knives',
    pic: seriesPic03,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F))',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
  {
    name: '食品/肉品加工系列',
    name_en: 'Processed Series',
    pic: seriesPic04,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F))',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
  {
    name: 'HACCP系列',
    name_en: 'HACCP Series',
    pic: seriesPic05,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F))',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
  {
    name: '輔助器具/配件系列',
    name_en: 'Ancillaries Series',
    pic: seriesPic06,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F))',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
  {
    name: '國外代理系列',
    name_en: 'Acting Commodity',
    pic: seriesPic07,
    to: 'Products',
    list: [
      {
        name: '頂級系列 (1461F)',
      },
      {
        name: '專業系列 (1201F))',
      },
      {
        name: '經典系列 (5301F)',
      },
      {
        name: '實用系列 (8321F)',
      },
    ],
  },
];

export const products = [
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: seriesPic01 },
      { name: '藍色', value: 'blue', pic: seriesPic02 },
      { name: '黑色', value: '#000', pic: seriesPic03 },
      { name: '紅色', value: 'red', pic: seriesPic04 },
      { name: '藍色', value: 'blue', pic: seriesPic05 },
      { name: '黑色', value: '#000', pic: seriesPic06 },
      { name: '紅色', value: 'red', pic: seriesPic07 },
      { name: '藍色', value: 'blue', pic: productPic },
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
  {
    name: '主廚刀(分刀)',
    no: '1461F17',
    size: '2.5"/7cm',
    colors: [
      { name: '黑色', value: '#000', pic: productPic },
      { name: '紅色', value: 'red', pic: productPic },
      { name: '藍色', value: 'blue', pic: productPic },
    ],
  },
];

export const knowledge = {
  name: 'HACCP',
  top: [
    {
      name: '危害分析和重點管制系統',
      name_en: 'HACCP',
      content: 'HACCP-Hazard Analysis and Critical Control Point 定義：在提供餐飲或製作食品之前，運送和儲藏的過程當中，所可能產生危害食品衛生或造成污染的因素包括(1.生物性 2.物理性 3.化學性)而在製造過程中加以設點管理控制，並於事後評估分析，加以紀錄檢討。 Haccp系統是一套目前世界公認食品衛生最具成效、預防性之自主式製程管理系統，以事前分析，從原料到產品製造過程中的每個步驟，分析可能產生的危害，然後依危害的機率與後果嚴重性，訂定重要管制點，有效預防控制危害的發生，或在危害產生時，可立即採取矯正措施，去除危害，以達到確保產品安全為目標，提升食品業者的水準。',
      pic: knowledgePic01,
    },
    {
      name: '六協廚刀符合NSF認證',
      name_en: 'NSF',
      content: '六協廚刀擁有 NSF® 國際認證. NSF® 是一個公正的非政府機構基金會，專門從事對產品的原料安全、結構性完整、產品資訊和降低有害物質以及非健康相關的汙染物，淨化性能表現的最低標準的認證，所以說有 NSF® 認證，就能說無毒產品的標誌。',
      pic: knowledgePic02,
    },
    {
      name: '德國進口 1.4116 鋼材',
      name_en: '鋼材介紹',
      content: '六協 (Atlantic Chef) 使用德國 1.4116 鋼材，內含 碳、鉻、鉬、釩等原素，經由熱處理，刀刃切削及細緻研磨等多道工序所製成的廚刀，硬度可達洛氏硬度標尺56度以上，同時具有耐磨耗，防鏽抗腐蝕等特性，六協廚刀所使用的鋼材是製造專業廚刀最合適的原料之一。',
      list: [
        '一體鍛造成型的刀身，採用德國的鋼廠製造的鋼材，內含碳、鉻、鉬、釩等元素最適用製作廚刀的材料，能防鏽且耐腐蝕，終身耐用。',
        '刀身經由熱處理、刀刃切削及細緻研磨等多道工序，進而完成一把具備銳利刃口，鋒利持久，易切且易再磨利的專業廚刀。',
        '當使用全刀刃切割食材時，鍛造成型的護手，能夠提供穩定與靈巧掌控廚刀而不傷手。',
      ],
      pic: knowledgePic03,
    },
  ],
  bottom: [
    {
      name: '使用磨刀石',
      list: [
        '使用前將磨刀石置於水中 20-30 分鐘。',
        '先使用較低番號的磨刀石，將刀刃以個人習慣的角度置於磨刀石上，將刀刃來回在磨刀石上拖拉數十回，如果刀刃長於磨刀石需調整位置，使整個刀刃可以磨利均勻。',
        '單面磨完之後，再磨另外一面，直到個人所須的角度和利度為止。',
        '若有需要，最後再使用較高番號的磨刀石，將粗磨時所產生的小毛刺去除，讓刀鋒達到個人所需的最大利度。',
      ],
      pic: knowledgePic04,
    },
    {
      name: '使用磨刀棒',
      list: [
        '將磨刀棒，以近乎垂直的角度置於防滑桌面握好。並將刀刃以大約 20 度的角度，靠緊磨刀棒。',
        '從刀刃底部開始，用些許的力道，以輕劃的方式，順著磨刀棒往下劃下。',
        '繼續將刀刃，朝刀尖的方向，順著磨刀棒往下劃下。再劃刀之時，若聽到刺耳嘈雜的磨刀聲，代表太過用力，需減輕力道，才可達成預期的效果。',
        '每次磨刀程序完成於將刀刃拉往自己身體的方向直到刀尖到達磨刀棒頂部為止。',
        '重復上述步驟直刀個人所習慣及適用的刀口利度為止。',
      ],
      pic: knowledgePic05,
    },
  ],
};
export const knowledgeEN = {
  name: 'HACCP',
  top: [
    {
      name: 'HACCP-Hazard Analysis and Critical Control Point',
      name_en: 'HACCP',
      content: 'Hazard Analysis Critical Control Points (HACCP) is an internationally recognized method of identifying and managing food safety related risk and, when central to an active food safety program, can provide your customers, the public, and regulatory agencies assurance that a food safety program is well managed.HACCP is a management system in which food safety is addressed through the analysis and control of biological, chemical, and physical hazards from raw material production, procurement and handling, to manufacturing, distribution and consumption of the finished product.',
      pic: knowledgePic01,
    },
    {
      name: ' Certified by NSF.',
      name_en: 'NSF',
      content: 'Atlantic Chef knives are certified by NSF.NSF, National Sanitation Foundation, is a not-for-profit, non-governmental organization that develops standards and provides product certification and education in the field of public health and safety.',
      pic: knowledgePic02,
    },
    {
      name: 'German Steel, DIN 1.4116.',
      name_en: '鋼材介紹',
      content: 'Atlantic Chef uses German Stainless Steel, DIN 1.4116, wich consists of Carbon, Chromium, Vanadium, Molybdenum and several other materials.    After well hardening and tempering, the hardness of the blades can reach as high as HrC 56 and above.    The razor sharp blade edge also offers long-lasting sharpness, easy edge maintenance and rapid re-sharpening features..',
      pic: knowledgePic03,
    },
  ],
  bottom: [
    {
      name: '使用磨刀石',
      list: [
        'The sharpening stone should be soaked in water for 3-5 minutes before you use.',
        'Hold the knife so that the blade meets the stone at your preferable angle, and then push back an forth across the stone in smooth steady strokes maintaining the same angle.',
        'After sharpening one side of blade, repeat above process on the other side of blade until it reaches your preferable angle and sharpness.',
        'If necessary, using finer grit stone to remove the micro burrs on the blade edge to reach the ultimate sharpness you desire.',
      ],
      pic: knowledgePic04,
    },
    {
      name: '使用磨刀棒',
      list: [
        'Hold the sharpening steel on one hand in a near-vertical position with the point resting on a flat non-slipper surface, Hold the knife on the other hand so that the blade meets the sharpening steel at your preferable angle.',
        'Start with the heel of the knife against one side of the steel.  Maintain light pressure and draw the knife down the shaft of the steel.',
        'Continue in a smooth motion.  Listen for a light ringing sound – a heavy grinding sound indicates too much pressure  is being applied.',
        'finish by drawing the knife toward you, all the way through the tip.',
        'Repeat on the other side of steel.  Repeat step 2 – 5 several times until it reaches your preferable sharpness.  We the movement is completed, wipe the knife with a clean cloth.',
      ],
      pic: knowledgePic05,
    },
  ],
};

export const locations = [
  {
    name_en: 'TAIPEI METRO <br>AREA',
    name_cn: '大台北地區',
    name: '大台北',
    to: 'Marketing',
    cities: ['台北市', '新北市', '基隆市'],
  },
  {
    name_en: 'TAIPEI METRO <br>AREA',
    name_cn: '桃竹苗地區',
    name: '桃竹苗',
    to: 'Marketing',
    cities: ['桃園市', '新竹縣', '新竹市', '苗栗縣'],
  },
  {
    name_en: 'TAIPEI METRO <br>AREA',
    name_cn: '中彰投地區',
    name: '中彰投',
    to: 'Marketing',
    cities: ['台中市', '彰化縣', '南投縣'],
  },
  {
    name_en: 'TAIPEI METRO <br>AREA',
    name_cn: '雲嘉南地區',
    name: '雲嘉南',
    to: 'Marketing',
    cities: ['雲林縣', '嘉義縣', '嘉義市', '台南市'],
  },
  {
    name_en: 'TAIPEI METRO <br>AREA',
    name_cn: '高屏地區',
    name: '高屏',
    to: 'Marketing',
    cities: ['高雄市', '屏東縣'],
  },
  {
    name_en: 'TAIPEI METRO <br>AREA',
    name_cn: '宜蘭及花東地區',
    name: '宜蘭及花東',
    to: 'Marketing',
    cities: ['宜蘭縣', '花蓮縣', '台東縣'],
  },
];

export const companies = [
  { branch: '總公司', name: '六協興業股份有限公司' },
  { branch: '中國發貨中心', name: '蘇州德協商貿有限公司' },
];

export const copyRight = 'Copyright 2021 Liow Shye Enterprise Co., Ltd. All Rights Reserved.';

export const contactInfo = [
  {
    color: 'black',
    bg: true,
    title: '總公司',
    name: '六協興業股份有限公司',
    enName: 'Liow Shye Enterprise Co., Ltd.',
    google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3641.454527840395!2d120.71977781498842!3d24.120670784408137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346922d01c42391b%3A0x197c984591ff13c7!2zNDA25Y-w5Lit5biC5aSq5bmz5Y2A57K-576O6LevMzHomZ8!5e0!3m2!1szh-TW!2stw!4v1636001155590!5m2!1szh-TW!2stw',
    information: [
      {
        icon: 'Location',
        value: '台灣台中市太平區精美路 31 號',
      },
      {
        icon: 'Phone',
        value: '(04) 2279 - 1105',
      },
      {
        icon: 'Email',
        value: 'info@atlanticchef.com',
      },
      {
        icon: 'Fax',
        value: '(04) 2270 - 0506',
      },
    ],
  },
  {
    bg: false,
    darkMode: true,
    title: '中國發貨中心',
    name: '蘇州德協商貿有限公司',
    enName: 'Suzhou Ac Dickdevelopment Co., Ltd.',
    google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.1014663223905!2d121.11591721560414!3d31.4663948813875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b247841660f519%3A0xdd45677b56028f4b!2z5Lit5ZyL5rGf6IuP55yB6IuP5bee5biC5aSq5LuT5biC5rSb6Ziz5Lic6LevNzDlj7cg6YKu5pS_57yW56CBOiAyMTU0MTY!5e0!3m2!1szh-TW!2stw!4v1636100351030!5m2!1szh-TW!2stw',
    information: [
      {
        icon: 'Location',
        value: '江苏省太仓市经济开发区洛阳东路70号',
      },
      {
        icon: 'Phone',
        value: '0512-53593559',
      },
      {
        icon: 'QQ',
        value: '448138578',
      },
      {
        icon: 'Fax',
        value: '0512-53593755',
      },
    ],
  },
];

export const spirit = {
  mainTitle: 'OUR SPIRIT',
  subTitle: '經營理念',
  contentTitle: ['堅持品質', '專業創新', '台灣生產'],
  content: '六協興業股份有限公司是一家經營外銷出口的製刀廠， 1977 年成立至今已超過三十年的時間，在堅持品質的理念及專業創新的技術之下，我們所服務的客戶都是國際知名的廚刀品牌，在國際間備受肯定與讚賞。近年來台灣產業因為節省人工成本的考量下，企業不斷的外移至鄰近其它亞洲國家，而較具規模的製刀廠在國內所剩無幾，正因為經營者對員工的責任感以及對紮根本土的使命感 — 六協堅持在台灣生產！',
};

export const spiritEN = {
  mainTitle: 'OUR SPIRIT',
  subTitle: 'Business Philosophy',
  contentTitle: ['Quality.', 'Innovation.', 'Made in Taiwan.'],
  content: 'Liow Shye Enterprise is a knife manufacturer and exporter.    Funded in 1977, the company has been successfully established over 45 years.    We are recognized and reputed by all of our renowned international customers for our insistence on good quality and professional and novel technologies we adopted for our products.    Under the consideration of labor cost cut-down, numerous corporations in the knife industry have decided or been forced to moved out of Taiwan in the past decades; leaving Atlantic Chef the only manufacturer with business scale large enough survive in Taiwan.    With responsibility we hold to our employees as well as our sense of belonging to our root, we insisted to manufacture in Taiwan.',
};

export const features = {
  mainTitle: 'OUR FEATURES',
  subTitle: '公司特色',
  contentTitle: '德國進口一體式鋼材',
  content: '六協從德國直接進口，與所有德國知名刀廠採用同等級的鋼材來製造刀具，其產品具備易切、鋒利持久、易再磨利…等優點。此外六協更研發出多種不同材料並設計符合人體工學、握感舒適的手柄。讓 Atlantic Chef 廚刀成為每一位專業廚師在調理美食時是不可或缺的刀具。',
};
export const featuresEN = {
  mainTitle: 'OUR FEATURES',
  subTitle: 'Features',
  contentTitle: 'German high carbon stainless steel.',
  content: 'We directly import from Germany the high-quality stainless steel used by all well-established German knife manufacturers for all of our knives.    Our products are made of blades that are sharp, durable, and easy to maintain.  In addition, we have also developed all types of handles made of different high-quality materials.    Our knives are all well designed, so that they are all ergonomic and comfortable to hold.    It’s our goal to make Atlantic Chef knives a “must” knife for every professional users.',
};

export const process = {
  contentTitle: '層層把關的產品製程',
  description: '六協擁有最先進的製刀設備及技術，從刀刃成型到包裝完成，全部都由訓練有素的工作人員，層層把關一貫作業完成。',
};
export const processEN = {
  contentTitle: 'Advanced manufacturing processes',
  description: 'We own the most advanced knife production machineries and many years of experiences in knife production techniques. These production processes are all done in house, and we have well-trained employees to strictly and tightly control and inspect each step.',
};

export const prospect = {
  mainTitle: 'PROSPECTS',
  subTitle: '未來展望',
  content: [
    {
      title: '研究發展',
      description: '六協擁有優秀的研發團隊，從刀型、手柄設計，製作模具到產品完成，都可以精準確實地用最短的時間內在六協廠內完成。所以我們的產品可以因應市場需求作調整，有別於其它進口商品是固定不變的，並且我們有更完善的售後服務及品質保證。',
      imageName: 'img_3.png',
    },
    {
      title: '未來願景',
      description: '由製造端走向高附加價值的設計與品牌端，已是必行之路。六協興業於2006年開始進行以 Atlantic Chef 的品牌行銷國際的計劃，在這段時間的努力下，我們以華人市場為出發點，目前普遍的反應都非常的肯定，使用者也都給予極高的評價。我們有信心六協生產的廚刀可以讓技術人員操作的得心應手，主廚們在烹調食物時成為一種藝術及享受，讓六協頂極廚刀在專業主廚領域，師徒代代傳承下去！',
      imageName: 'img_4.png',
    },
  ],
};
export const prospectEN = {
  mainTitle: 'PROSPECTS',
  subTitle: '未來展望',
  content: [
    {
      title: 'Research and Development',
      description: 'We have an outstanding R&D team that works professionally from the design of blade shapes and handles to the manufacture of molds and samples.    Combining our professional attitude, pioneer technology and superior techniques, we are able to create practical and aesthetical knives for professional users.',
      imageName: 'img_3.png',
    },
    {
      title: 'Future Expectations.',
      description: 'Under the industrial development and competition, it is a definite trend for companies to evolve from manufacturing to added value design and brand promotion.    We started to implement our international marketing plan by promoting the Atlantic Chef brand.    With our striving efforts, we have received positive recognitions and encouraging responses from the food service markets around the world.    We are confident that together with our customers, we will witness the legend and success of Taiwanese knives and that Atlantic Chef knives will become an art and enjoyment for chefs and users.',
      imageName: 'img_4.png',
    },
  ],
};

export const colorList = {
  '#000000': { name: '黑色', name_en: 'black' },
  '#000': { name: '黑色', name_en: 'black' },
  '#FFFFFF': { name: '白色-糕餅麵包', name_en: 'white' },
  '#FFF': { name: '白色-糕餅麵包', name_en: 'white' },
  '#DE3333': { name: '紅色-紅肉生食', name_en: 'red' },
  '#47C942': { name: '綠色-蔬果類', name_en: 'green' },
  '#385FC2': { name: '藍色-海鮮類', name_en: 'blue' },
  '#54182A': { name: '咖啡色-熟食類', name_en: 'brown' },
  '#F1F50E': { name: '黃色-家禽類', name_en: 'yellow' },
  '#D3B3E3': { name: '紫色', name_en: 'purple' },
};
