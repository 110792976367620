<template>
  <div class="hamburger_wrapper">
    <div class="line"></div>
    <div class="line line-rotate"></div>
    <div class="line line-red"></div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.hamburger_wrapper {
  position: relative;
  margin-left: 20px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  > .line {
      position: absolute;
      width: 80%;
      height: 2px;
      background-color: $color-main;
      transition: transform 0.2s linear;
      &.line-red {
          background-color: $color-red;
      }
      &:nth-child(1) {
          top: 30%;
      }
      &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
      }
      &:nth-child(3) {
          top: 50%;
          transform: translateY(-50%);
      }
      &:nth-child(4) {
          bottom: 30%;
      }
  }
  &.active {
      .line {
          &:nth-child(1) {
              opacity: 0;
          }
          &:nth-child(2) {
              transform-origin: center;
              transform: translateY(-50%) rotate(45deg);
          }
          &:nth-child(3) {
              transform-origin: center;
              transform: translateY(-50%) rotate(-45deg);
          }
          &:nth-child(4) {
              opacity: 0;
          }
      }
  }
}
</style>
