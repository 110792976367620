import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/products/:lv1?/:lv2?',
    name: 'Products',
    component: () => import('../views/products/Index.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/Index.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news/Index.vue'),
  },
  {
    path: '/news/:uuid',
    name: 'News.Article',
    component: () => import('../views/news/Article.vue'),
  },
  {
    path: '/knowledge',
    name: 'Knowledge',
    component: () => import('../views/knowledge/Index.vue'),
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import('../views/marketing/Index.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/Index.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/search/Index.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'instant' };
  },
});

router.beforeEach(async (to, from, next) => {
  const store = await import('@/store/index');

  /** 每次換頁時，將body解鎖 */
  const el = document.getElementsByTagName('body')[0];
  el.classList.remove('lock');

  /** 將目前開啟的modal關閉 */
  store.default.commit('site/SET_MODAL', { show: false });

  next();
});

export default router;
