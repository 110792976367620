<template>
  <div id="app" class="noSelect">
    <Header />
    <router-view :style="styleObj" />
    <Footer />
    <Modal v-show="modalShow" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Modal from '@/components/modal/Index.vue';
import { isEqual } from '@/lib/lodash';

/**
 * Body Locked
 */
function bodyLock(status) {
  const bodyTarget = document.getElementsByTagName('body')[0];
  if (status) {
    bodyTarget.classList.add('lock');
  } else {
    bodyTarget.classList.remove('lock');
  }
}

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Modal,
  },
  data() {
    return {
      atHome: true,
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.site.headerHeight;
    },
    styleObj() {
      return {
        paddingTop: this.atHome ? '0px' : `${this.headerHeight}px`,
      };
    },
    modalShow() {
      return this.$store.state.site.modal.show;
    },
  },
  async created() {
    await this.$store.dispatch('site/initAllSerieses');
    await this.$store.dispatch('site/initMenuList');
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          if (newVal.name === 'Home') {
            this.atHome = true;
          } else {
            this.atHome = false;
          }
        }
      },
    },
    modalShow: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          bodyLock(newVal);
        }
      },
    },
  },
};
</script>
