import Vue from 'vue';

/** Swiper */
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

/** Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes, faSortDown, faChevronLeft, faChevronRight,
  faEye, faTrashAlt, faThLarge, faSquare, faCheck, faPrint,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF, faInstagram, faPinterestP, faTwitter,
  faWeixin, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/** AXIOS */
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';
import router from './router';
import store from './store';

/** Mixins */
import titleMixin from '@/mixins/titleMixin';

/** SCSS */
import '@/assets/scss/main.scss';
import '@/assets/scss/public.scss';

/** Font Awesome */
library.add(
  faTimes, faSortDown, faChevronLeft, faChevronRight,
  faEye, faTrashAlt, faThLarge, faSquare, faCheck,
  faPrint, faFacebookF, faInstagram, faPinterestP,
  faTwitter, faWeixin, faYoutube, faMapMarkerAlt,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.mixin(titleMixin);
Vue.use(VueAxios, axios);
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
